import React, { useMemo } from 'react';
import { Box, Typography, useMediaQuery, Theme } from '@material-ui/core';

import { FormattedMessage } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';

import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';

import { lightGray, white } from '../../constants/colors';
import useStyles from './styles';

const Title = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const formattedPathname = pathname?.replace(/[/]/, '');
  const isXs = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xs'));
  const { currentPath } = useStyles();

  const multipleItems = useMemo(() => {
    return [formattedPathname];
  }, [formattedPathname, isXs]);

  return (
    <Box display="flex" alignItems="center" className={currentPath}>
      {multipleItems.map((key, index) => {
        const isLast = index === multipleItems.length - 1;
        const lastElementColor = isLast ? white : lightGray;
        return (
          <Box
            display="flex"
            key={key}
            alignItems="center"
            color={lastElementColor}
            style={{ cursor: !isLast ? 'pointer' : '' }}
            onClick={() => {
              if (!isLast) {
                history.push(`/${key}`);
              }
            }}
          >
            {index > 0 && (
              <Box margin="0 13px" display="flex" alignItems="center">
                <ArrowRight
                  style={{
                    fill: lastElementColor,
                  }}
                />
              </Box>
            )}
            <Typography variant="body1">
              <FormattedMessage
                id={`sidebar.${key?.startsWith('geofence/') ? 'geofence' : key}`}
                defaultMessage={key}
              />
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default Title;
