import React from 'react';
import { Chip, Box, Avatar } from '@material-ui/core';
import { useStyles } from './index';
import { FamilyDetailsType } from 'types';
import { useFile } from 'utils/get-file';

interface Props {
  data: FamilyDetailsType;
  assignedAgentId?: number;
  handleClick: any;
  isViewOnlyAgent: any;
  panicInPlace?: string | Date | null;
  agentId?: number;
  isBusy?: boolean;
  disableClick?: boolean;
}

const AgentChip = ({ data, assignedAgentId, handleClick, isViewOnlyAgent, panicInPlace, agentId, isBusy, disableClick }: Props) => {
  const agentImage = useFile(data.photo ? `v1/storage/file/public/${data.photo.uuid}` : '');
  const isSelected = (el: FamilyDetailsType) => el.id === Number(agentId) || el.id === assignedAgentId;

  const setAgentColor = (el: FamilyDetailsType) => {
    if (isSelected(el) && panicInPlace) {
      return '#269CF4';
    }
    if (isBusy) {
      return '#AEAEAE';
    }
    return '#D32F2F';
  };

  const { chip, agentBox } = useStyles();
  return (
    <Box m={1} key={data.id} className={agentBox}>
      <Chip
        className={data?.id === assignedAgentId || isViewOnlyAgent(data?.id) ? chip : ''}
        style={{
          color: `${data.id === assignedAgentId ? 'white' : setAgentColor(data)}`,
          backgroundColor: `${data.id === assignedAgentId ? setAgentColor(data) : 'transparent'}`,
          border: `2px solid ${setAgentColor(data)}`,
        }}
        label={data.fullName}
        avatar={<Avatar src={agentImage} />}
        disabled={isBusy}
        onClick={(e) => disableClick ? null : handleClick(e, data.id)}
      />
    </Box>
  );
};

export default AgentChip;
