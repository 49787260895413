import * as React from 'react';
import * as Sentry from '@sentry/browser';

interface State {
  error?: Error | null;
  errorInfo?: React.ErrorInfo;
}

interface Props {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error });
    // Sentry.captureException(error, { extra: errorInfo });
    Sentry.captureException(error);
    Sentry.showReportDialog();
    Sentry.setExtra('extra', errorInfo);
  }

  public render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      return null;
    }
    return children;
  }
}

export default ErrorBoundary;
