import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router-dom';

import { IAppState } from '../../types';
import { togglePanicDetailsModal } from 'actions/panic';
import useStyles from './styles';
import { useFile } from '../../utils/get-file';

interface Props {
  panicId: number;
  status?: string;
  intl: any;
}

const formatNumber = (phone: string) =>
  phone
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,3})/)
    ?.slice(1)
    ?.join(' ');

const PanicNotification = ({ panicId, status = '', intl }: Props) => {
  const { image } = useStyles();
  const panic = useSelector(({ panic: { byId } }: IAppState) => byId[panicId]);
  const { user } = panic || {};

  const userImage =  useFile(user?.info?.photo ? `v1/storage/file/protected/${user?.info?.photo?.uuid}` : '');
  if (!panic) return null;

  return (
    <Box display="flex">
      <div className={image} style={{ backgroundImage: `url("${userImage}")` }} />
      <Box display="flex" flexDirection="column" marginLeft="10px">
        <Typography variant="subtitle2">
          <FormattedMessage
            id={`panic_statuses.${status}`}
            values={{
              entity: intl.formatMessage({
                id: `panic_users.${panic.closedBy ?? 'operator'}`,
              }),
            }}
          />
        </Typography>
        <Typography variant="body1">{user.fullName}</Typography>
        <Typography variant="body2">
          +
          {formatNumber(user.phone)}
        </Typography>
      </Box>
    </Box>
  );
};

interface ActionBtns {
  id: number;
  notifKey?: string | number;
  withDetails?: boolean;

}

export const PanicNotificationActions = ({ id, notifKey, withDetails }: ActionBtns) => {
  const { closeSnackbar } = useSnackbar();
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const showPanicDetails = () => {
    if (pathname !== '/map') {
      history.push('/map');
    }
    dispatch(togglePanicDetailsModal({ id, show: true }));

    closeSnackbar(notifKey);
  }

  return (
    <>
      {withDetails && (
        <Button
          color="primary"
          onClick={showPanicDetails}
        >
          <Typography variant="subtitle1">
            <FormattedMessage id="notification_buttons.view" />
          </Typography>
        </Button>
      )}
      <Button color="primary" onClick={() => closeSnackbar(notifKey)}>
        <Typography variant="subtitle1">
          <FormattedMessage id="notification_buttons.dismiss" />
        </Typography>
      </Button>
    </>
  );
};

export default injectIntl(PanicNotification);
