import { useEffect, useState } from 'react';

import api from '../api';

const getFile = async (url: string) => {
  try {
    const file = await api.request('GET', url, {}, {}, { responseType: 'blob' });

    return file;
  } catch (e) {
    return '';
  }
};

export const useFile = <T>(url: string | undefined, updateIf: T[] = []) => {
  const [file, handleFile] = useState('');

  useEffect(() => {
    const isValidUrl = url ? ['undefined', 'null'].every((el) => !url.includes(el)) : false;
    if (isValidUrl) {
      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
      getFile(url!).then((data) => {
        if (data) {
          handleFile(URL.createObjectURL(data));
        }
      });
    }
  }, [url, ...updateIf]);

  return file;
};

const getFileRaw = async (url: string) => {
  try {
    const file = await api.requestRaw('GET', url, {}, {}, { responseType: 'blob' });

    return file;
  } catch (e) {
    return null;
  }
};

export const useFileObject = (url?: string) => {
  const [file, setFile] = useState<File | undefined>();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!url || ['undefined', 'null'].some((el) => url.includes(el))) {
      setLoaded(true);
      return;
    }

    getFileRaw(url)
      .then((response) => {
        if (!response?.data) {
          return;
        }

        const contentType = response.headers['content-type'];

        setFile(response?.data && new File(
          [response.data as BlobPart],
          `agent-photo.${contentType.split('/').pop()}`,
          { type: contentType }
        ));
      })
      .finally(() => setLoaded(true));
  }, [url]);

  return { content: file, loaded };
};

export default getFile;
