import React, { useState, useEffect } from 'react';
import { Box, Grid, CardMedia, Typography, CardContent, List, ListItem } from '@material-ui/core';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import defaultFamilyImg from 'assets/default-family.svg';
import HeightIcon from 'assets/height-icon.svg';
import WeightIcon from 'assets/weight-icon.svg';
import AgeIcon from 'assets/age-icon.svg';
import CarIcon from 'assets/car.svg';
import familyIcon from 'assets/family.svg';
import gpsIcon from 'assets/gps.svg';
import useStyles from './styles';
import { formatPhoneNumber } from 'utils/formatPhone';
import { FullScreenImage } from 'components/modal';
import getFile, { useFile } from 'utils/get-file';
import { IAddressDetailsProps, ICarDetailsProps, PersonDetailsType } from 'types';

type Props = {
  clientData: PersonDetailsType;
};

const ClientCard = ({ clientData }: Props) => {
  const [yearsOld, setYearsOld] = useState(0);
  const [carImages, handleCarImages] = useState<any[]>([]);
  const [addressImages, handleAddressImages] = useState<any[]>([]);

  useEffect(() => {
    // get car images
    if (clientData?.cars?.length > 0) {
      const carImagesRequest = clientData.cars
        .filter(({ photos }) => photos && photos[0])
        .map(({ photos }) => getFile(`v1/storage/file/protected/${photos?.[0]}`));

      carImagesRequest.length > 0 &&
        Promise.all(carImagesRequest).then((data) => {
          if (data) {
            handleCarImages(data.map((image) => URL.createObjectURL(image)));
          }
        });
    }
  }, [JSON.stringify(clientData?.cars)]);

  useEffect(() => {
    // get address images
    if (clientData?.places?.length > 0) {
      const addressRequests = clientData.places
        .filter(({ photos }) => photos && photos[0])
        .map(({ photos }) => getFile(`v1/storage/file/protected/${photos?.[0]}`));

      addressRequests.length > 0 &&
        Promise.all(addressRequests).then((data) => {
          if (data) {
            handleAddressImages(data.map((image) => URL.createObjectURL(image)));
          }
        });
    }
  }, [JSON.stringify(clientData?.places)]);

  const now = new Date();

  useEffect(() => {
    const age = moment(clientData?.info?.birthdate);

    const years = moment().diff(age, 'years');
    setYearsOld(years);
  }, [now, clientData]);

  const fullAddress = (place: IAddressDetailsProps) =>
    [
      place?.region ?? '',
      [place?.streetAddress ?? '', place?.streetNumber ?? '', place?.apartment ?? ''].join(' '),
    ].join(', ');

  const carFullDetails = (car: ICarDetailsProps) =>
    [car?.brand ?? '', car?.model ?? '', car?.type ?? '', car?.license ?? ''].join(' ');

  const owner = clientData.inFamily && clientData.inFamily.find((x) => x.user.plan.isActive)?.user;

  const clientImage = useFile(
    clientData?.info?.photo ? `v1/storage/file/protected/${clientData?.info?.photo?.uuid}` : ''
  );

  const {
    cardMediaStyle,
    cardContent,
    divider,
    descItem,
    iconStyles,
    additionalInfo,
    clientCard,
    mainContent,
    secondaryContent,
    cardHeader,
    callTo,
    familyContainer,
  } = useStyles();

  if (clientData) {
    return (
      <Box className={clientCard}>
        <Box mb={2} className={cardHeader}>
          <Typography variant="body2">
            <FormattedMessage id="common.client_info" />
          </Typography>
        </Box>
        {/* main user info */}
        <Grid container className={mainContent}>
          <FullScreenImage src={clientImage}>
            <CardMedia className={cardMediaStyle} image={clientImage || defaultFamilyImg} />
          </FullScreenImage>
          <CardContent className={cardContent}>
            <Box pl={1}>
              <Typography variant="body1">
                {clientData.fullName || '-'}
                <span>{` [ID: ${clientData?.id}]`}</span>
              </Typography>
            </Box>
            <Box pl={1} display="flex">
              <Typography variant="body1">
                {clientData?.phone ? (
                  <a href={`tel:${clientData?.phone}`} className={callTo}>
                    +{formatPhoneNumber(clientData?.phone)}
                  </a>
                ) : (
                  '-'
                )}
              </Typography>
              <Box pl={1}>
                <Typography>
                  /&nbsp;
                  <FormattedMessage id="family.emergencyContact" />
                  {`: `}
                  {clientData?.info?.emergencyContact ? (
                    <a href={`tel:${clientData?.info?.emergencyContact}`} className={callTo}>
                      {formatPhoneNumber(clientData?.info?.emergencyContact)}
                    </a>
                  ) : (
                    '-'
                  )}
                </Typography>
              </Box>
            </Box>
            <Box display="flex">
              <Box
                display="flex"
                textAlign="center"
                justifyContent="space-between"
                alignItems="center"
                className={descItem}
              >
                <Typography variant="body1">{`${clientData?.info?.height || 'N/A'} cm`}</Typography>
                <img src={HeightIcon} alt="height-icon" />
              </Box>
              <div className={divider} />
              <Box
                display="flex"
                textAlign="center"
                justifyContent="space-between"
                alignItems="center"
                className={descItem}
              >
                <Typography variant="body1">{`${clientData?.info?.weight || 'N/A'} kg`}</Typography>
                <img src={WeightIcon} alt="weight-icon" />
              </Box>
              <div className={divider} />
              <Box
                display="flex"
                textAlign="center"
                justifyContent="space-between"
                alignItems="center"
                className={descItem}
              >
                <Typography variant="body1">{`${yearsOld || 'N/A'} y/o`}</Typography>
                <img src={AgeIcon} alt="age-icon" />
              </Box>
            </Box>
          </CardContent>
        </Grid>
        {/* secondary user info */}
        <div className={secondaryContent}>
          {/* location section */}
          <Box className={additionalInfo}>
            <FullScreenImage images={addressImages}>
              <img className={iconStyles} width={24} height={24} src={gpsIcon} alt="address" />
            </FullScreenImage>
            <div>
              <Typography variant="body2" color="textPrimary">
                <FormattedMessage id="common.address" />
              </Typography>
              {!clientData?.places || clientData?.places.length === 0 ? (
                <Typography color="textSecondary" variant="body2">
                  N/A
                </Typography>
              ) : (
                <List disablePadding dense>
                  {clientData?.places?.map((place) => (
                    <ListItem dense disableGutters key={place.id}>
                      <Typography color="textSecondary" variant="body2">
                        {fullAddress(place)}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          </Box>
          {/* cars section */}
          <div className={additionalInfo}>
            <FullScreenImage images={carImages}>
              <img className={iconStyles} width={24} height={24} src={CarIcon} alt="car" />
            </FullScreenImage>
            <div>
              <Typography variant="body2" color="textPrimary">
                <FormattedMessage id="common.car" />
              </Typography>
              {!clientData?.cars || clientData?.cars.length === 0 ? (
                <Typography color="textSecondary" variant="body2">
                  N/A
                </Typography>
              ) : (
                clientData?.cars?.map((car) => (
                  <Typography color="textSecondary" key={car.id} variant="body2">
                    {carFullDetails(car)}
                  </Typography>
                ))
              )}
            </div>
          </div>
          {/* family members section */}
          <div className={additionalInfo}>
            <img className={iconStyles} width={24} height={24} src={familyIcon} alt="family" />
            <div>
              <Typography variant="body2" color="textPrimary">
                <FormattedMessage id="family.title" />
              </Typography>
              <Typography variant="body2" color="textSecondary" className={familyContainer}>
                {clientData?.inFamily?.length > 0 ? (
                  <>
                    {owner?.phone && (
                      <a href={`tel:${owner?.phone}`} className={callTo}>
                        {`+${formatPhoneNumber(owner?.phone)} `}
                      </a>
                    )}
                    <strong>{owner?.fullName}</strong>
                    <span>
                      (<FormattedMessage component="span" id="common.head" />)
                    </span>
                  </>
                ) : (
                  '-'
                )}
              </Typography>
            </div>
          </div>
        </div>
      </Box>
    );
  }
  return null;
};

export default ClientCard;
