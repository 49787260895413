import * as c from '../constants/actions';
import { createReducer } from '../utils';
import { ISettingsStoreType, MapType } from 'types';

const initialState: ISettingsStoreType = {
  isGoogleMapEnabled: true,
  soundVolume: 1,
};


export default createReducer(initialState, {
  [c.TOGGLE_GOOGLE_MAP]: (state: ISettingsStoreType, { payload }: { payload: ISettingsStoreType}) => {
    return {
      ...state,
      isGoogleMapEnabled: payload.isGoogleMapEnabled,
    }
  },
  [c.SAVE_SOUND_VOLUME]: (state: ISettingsStoreType, { payload }: { payload: ISettingsStoreType}) => {
    return {
      ...state,
      soundVolume: payload.soundVolume,
    }
  },
  [c.GET_USER_SETTINGS_SUCCESS]: (state: ISettingsStoreType, { payload }: { payload: { map?: MapType, volume?: number }}) => {
    return {
      ...state,
      isGoogleMapEnabled: payload.map ? payload.map === MapType.GOOGLE : state.isGoogleMapEnabled,
      soundVolume: payload.volume ? payload.volume / 100 : state.soundVolume,
    }
  }
});
