import { makeStyles, Theme } from '@material-ui/core';
import { darkPaper, gray } from '../../constants/colors';

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  appBar: {
    marginLeft: 0,
    boxShadow: 'none',
    overflow: 'auto',
    WebkitOverflowScrolling: 'auto',
    width: '100%',
    '& img': {
      cursor: 'pointer',
    },
    backgroundColor: darkPaper,
    borderBottom: `1px solid ${gray}`,
  },
  toolBar: {
    overflow: 'auto',
    WebkitOverflowScrolling: 'auto',
  },
  menuButton: {
    marginRight: 20,
  },
  triggerMenu: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    border: '2px solid #fff',
  },
  secondaryMenu: {
    '& button': {
      width: '100%',
      [breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
  },
  mobileView: {},
  currentPath: {
    minWidth: 'min(150px, 50%)',
  },
}));

export default useStyles;
