import React from 'react';
import { Dialog } from '@material-ui/core';
import useStyles from './styles';
import { useFile } from 'utils/get-file';

type Props = {
  open: boolean;
  toggleModal: Function;
  reportVideoId?: string;
  fullWidth?: boolean;
};

const VideoModal = ({ open, toggleModal, reportVideoId }: Props) => {
  const reportVideo = useFile(
    reportVideoId
      ? `v1/storage/file/protected/${reportVideoId}`
      : '',
    [reportVideoId]
  );
  const classes = useStyles();
  return (
    <Dialog
      fullWidth={false}
      maxWidth={false}
      open={open}
      onClose={() => {
        toggleModal(false);
      }}
    >
      <video src={reportVideo} autoPlay loop className={classes.image} controls/>
    </Dialog>
  );
};

export default VideoModal;
